<template>
  <v-form v-model="valid" ref="form" lazy-validation>
    <v-container fluid>
      <SetFormLanguage @changeFormLanguage="onChangeFormLanguage" @deleteLanguage="onDeleteLanguage" :isEditable="isEditable" />
      <v-row>
        <v-col cols="12" sm="6">
          <div class="d-flex align-center">
            <div class="mr-3">Назва:</div>
            <v-text-field v-model="newNotification.content[languageCode].title" :rules="[rules.required]"></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <div class="text-h6 mb-5">Подія</div>
          <template v-if="isSettingsPage">
            <v-datetime-picker
              v-model="newNotification.start_at"
              :textFieldProps="textFieldProps"
              :datePickerProps="datePickerProps"
              :timePickerProps="timePickerProps"
              :key="newNotification.start_at ? `start-${Date.now()}` : null"
              timeFormat="HH:mm:ss"
              clear-text="очистити"
              ok-text="так"
              label="Термін дії з"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
            <v-datetime-picker
              v-model="newNotification.expired_at"
              :textFieldProps="textFieldProps"
              :datePickerProps="datePickerProps"
              :timePickerProps="timePickerProps"
              :key="newNotification.expired_at ? `expired-${Date.now()}` : null"
              timeFormat="HH:mm:ss"
              clear-text="очистити"
              ok-text="так"
              label="Термін дії до"
            >
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
            <v-select
              v-model="newNotification.event"
              :items="pushNotificationEventsDictionary"
              :rules="[rules.required]"
              @change="setPushVariables"
              item-text="name"
              item-value="value"
              label="Подія"
              dense
              outlined
            ></v-select>
          </template>
          <v-select
            v-model="newNotification.type"
            :items="pushNotificationTypesDictionary"
            :rules="[rules.required]"
            item-text="name"
            item-value="value"
            label="Тип пуш-повідомлення"
            dense
            outlined
          ></v-select>
          <v-select
            v-model="newNotification.is_save"
            :items="booleanOptions"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            label="Зберегти повідомлення клієнту"
            dense
            outlined
          ></v-select>
          <v-select
            v-model="newNotification.receiver_type"
            :items="pushNotificationRecipientsDictionary"
            :rules="[rules.required]"
            :disabled="!!newNotification.receivers.length"
            item-text="name"
            item-value="value"
            label="Групи отримувачів"
            dense
            outlined
            clearable
          ></v-select>
          <PagingAutocomplete
            :value="newNotification.receivers"
            :options="users"
            :options-meta="usersMeta"
            :options-params="usersOptions"
            :disabled="newNotification.receiver_type !== 4"
            @change="(val) => (newNotification.receivers = val)"
            label="Користувачі"
            placeholder="Почніть вводити текст для пошуку"
            no-filter
            item-text="phone"
            item-value="id"
            clearable
            multiple
            dense
            outlined
          >
            <template v-slot:selection="{ item }"> {{ item.first_name }} {{ item.last_name }} ({{ item.phone }}) </template>
            <template v-slot:item="{ item }"> {{ item.first_name }} {{ item.last_name }} ({{ item.phone }}) </template>
          </PagingAutocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="4" offset-md="2">
          <div class="text-h6 mb-5">Місце</div>
          <PushNotificationFilters :filter-defs="filterDefs" :is-visible="showPlaceFilter" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea
            v-model="newNotification.content[languageCode].body"
            ref="textarea"
            :rules="[rules.required]"
            outlined
            label="Текст повідомлення"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="6">
          <div v-if="newNotification.event">
            <div class="mb-2">Доступні змінні для відображення в тексті пуш повідомлень:</div>
            <v-chip
              v-for="(variable, index) in pushVariables"
              :key="index"
              @click="insertText(variable)"
              class="mr-1 mb-1"
              color="primary"
              outlined
              small
            >
              {{ variable }}
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end">
        <v-btn @click="openModal" color="primary" small>Зберегти</v-btn>
      </div>
      <ConfirmActionModal @closeModal="showModal = false" :showModal="showModal">
        <template v-slot:title>Зберегти?</template>
        <template v-slot:text>Правило буде сформовано</template>
        <template v-slot:buttons>
          <v-btn @click="submit" color="primary" text>Так</v-btn>
          <v-btn @click="showModal = false" text>Нi</v-btn>
        </template>
      </ConfirmActionModal>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PUSH_NOTIFICATION_SCHEMA } from '@/const/apiSchemas'
import CloneDeep from 'lodash/cloneDeep'
import ManageFiltersOptions from '@/mixins/manageFiltersOptions'
import moment from 'moment'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'NotificationForm',

  components: {
    PagingAutocomplete: () => import('@/components/common/PagingAutocomplete'),
    SetFormLanguage: () => import('@/components/common/forms/SetFormLanguage'),
    PushNotificationFilters: () => import('@/components/common/filters/PushNotificationFilters'),
    ConfirmActionModal: () => import('@/components/dialogs/ConfirmActionModal'),
  },

  mixins: [ManageFiltersOptions],

  props: {
    isEditable: {
      required: false,
      type: Boolean,
      default: false,
    },
    currentNotificationId: {
      required: false,
      default: null,
    },
  },

  data: () => ({
    PUSH_NOTIFICATION_SCHEMA: PUSH_NOTIFICATION_SCHEMA,
    newNotification: {},
    showModal: false,
    languageCode: 'uk',
    textFieldProps: {
      // hideDetails: 'auto',
      dense: true,
      outlined: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    pushVariables: [],
    usersOptions: null,
    booleanOptions: [
      {
        text: 'Так',
        value: true,
      },
      {
        text: 'Ні',
        value: false,
      },
    ],
    valid: true,
    rules: {
      required: (value) => !!value || value === false || 'Обовʼязкове поле',
    },
  }),

  computed: {
    ...mapState('users', ['users', 'usersMeta']),
    ...mapState('pushNotification', ['currentNotificationSettings']),
    ...mapState('dictionaries', [
      'pushNotificationTypesDictionary',
      'pushNotificationRecipientsDictionary',
      'pushNotificationEventsDictionary',
      'pushNotificationVariablesDictionary',
    ]),
    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    currentCompanyId() {
      return +this.$route.params.id
    },
    isSettingsPage() {
      return this.$route.name === 'PushNotificationSettings'
    },
    showPlaceFilter() {
      return this.newNotification.receiver_type === 51 || this.newNotification.receiver_type === 52
    },
    filterDefs() {
      let defs = this.defaultFilterDefs.map((item) => ({ ...item }))
      defs[2].show = false
      defs[3].show = false
      defs[5].show = false
      return defs
    },
    convertedStartAtDate() {
      if (!this.newNotification.start_at) return ''
      return moment(this.newNotification.start_at).format(DEFAULT_DATE_TIME_FORMAT)
    },
    convertedExpiredAtDate() {
      if (!this.newNotification.expired_at) return ''
      return moment(this.newNotification.expired_at).format(DEFAULT_DATE_TIME_FORMAT)
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('users', ['loadUsers']),
    ...mapActions('companies', ['loadFilter']),
    ...mapActions('pushNotification', ['createPushNotifications', 'updatePushNotifications']),

    initialize() {
      if (this.isEditable) {
        this.newNotification = CloneDeep(this.currentNotificationSettings)
        this.setPushVariables()
      } else {
        this.newNotification = CloneDeep(this.PUSH_NOTIFICATION_SCHEMA)
        this.$set(this.newNotification, 'content', {})
        this.setNewNotificationContent()
      }
      this.newNotification.is_one_time = !this.isSettingsPage
      this.newNotification.companies[0] = this.currentCompanyId
      this.usersOptions = {
        loadingFunction: this.loadUsers,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
      }
      this.loadFilter(this.currentCompanyId)
    },
    setNewNotificationContent() {
      this.$set(this.newNotification.content, this.languageCode, {
        locale: this.languageCode,
        title: '',
        body: '',
      })
    },
    onChangeFormLanguage(lang = 'uk') {
      this.languageCode = lang
      if (!this.newNotification.content[this.languageCode]) {
        this.setNewNotificationContent()
      }
    },
    onDeleteLanguage(lang) {
      delete this.newNotification.content[lang]
      this.onChangeFormLanguage('uk')
    },
    setPushVariables() {
      const selectedItem = this.pushNotificationVariablesDictionary.find((item) => +item.name === this.newNotification.event)
      if (selectedItem) this.pushVariables = selectedItem.value
    },
    async insertText(text) {
      const textarea = this.$refs.textarea.$refs.input
      const startPos = textarea.selectionStart
      const endPos = textarea.selectionEnd
      const currentValue = this.newNotification.content[this.languageCode].body
      this.newNotification.content[this.languageCode].body =
        currentValue.substring(0, startPos) + text + currentValue.substring(endPos, currentValue.length)
      // *** Adjust cursor position after inserting text
      const newCursorPos = startPos + text.length
      await this.$nextTick()
      textarea.focus()
      textarea.setSelectionRange(newCursorPos, newCursorPos)
    },
    async openModal() {
      const isValid = await this.$refs.form.validate()
      if (isValid) this.showModal = true
    },
    closeModal() {
      this.showModal = false
      this.$refs.form.resetValidation()
    },
    navigateToNotificationsMain() {
      this.$router.push({
        name: 'PushNotificationMain',
      })
    },
    async submit() {
      this.newNotification.start_at = this.convertedStartAtDate
      this.newNotification.expired_at = this.convertedExpiredAtDate
      if (this.showPlaceFilter) {
        this.newNotification.balance_holders = this.selectedBalanceHolders
        this.newNotification.service_points = this.selectedServicePoints
        this.newNotification.devices = this.selectedDevices
      }
      if (this.isEditable) {
        const payload = {
          id: this.currentNotificationId,
          data: this.newNotification,
        }
        await this.updatePushNotifications(payload)
        this.navigateToNotificationsMain()
      } else {
        await this.createPushNotifications(this.newNotification)
        this.closeModal()
        this.initialize()
      }
    },
  },
}
</script>
